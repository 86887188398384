body {
  --fonts: 'Playfair Display', serif;
  font-family: var(--fonts);
  letter-spacing: 0.05rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: black;

  --secondary: #60efff;
  --two-color: #08b2f5;
  --three-color: #099bf1;
  --four-color: #0a85ed;
  --five-color: #0b6ee9;
  --six-color: #0c58e5;
  --primary: #0061ff;

  --deep: #242582;
  --accent: #f64c72;
  --dull: #553d67;
  --light-dull: #99738e;
  --light-deep: #3a3acf;
  --light-accent: #f64c72cc;

  /* current color scheme */
  --paste-blue: #5680e9;
  --light-blue: #84ceeb;
  --sky-blue: #5ab9ea;
  --light-purple: #c1c8e4;
  --purple: #8860d0;

  --bg-light-blue: #84ceebcc;
  --bg-sky-blue: #5ab9ea99;

  background: radial-gradient(
      circle at 50% 50%,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      at 30% 110%,
      var(--purple) 10%,
      var(--bg-sky-blue) 40%,
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(
      circle at -10% 30%,
      var(--purple) 15%,
      var(--bg-light-blue) 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      -90deg,
      var(--light-purple) 15%,
      var(--sky-blue) 45%,
      var(--sky-blue)
    );
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  color: var(--accent);
}

::selection {
  background: var(--light-accent);
  color: white;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--paste-blue);
  border-radius: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
